<!--
 * @Descripttion: 警告页面
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 08:51:17
-->

<template>
  <el-container :direction="vertical">
    <el-main class="no-auth">
      <img src="./../assets/img/no-auth.webp" />
      <p>请在企业微信里打开应用！</p>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    name: 'NoAuth'
  }
</script>

<style lang="scss" scoped>
.el-container {
  height: 100vh;
  .el-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img {
      margin-top: 50px;
      width: 400px;
    }
    p {
      color: #74b9ff;
      font-size: 30px;
      transition: all 0.3s ease-in;
      &:hover {
        cursor: pointer;
        transform: scale(1.1) translateY(-5px) ;
      }
    }
  }
}
</style>
